import React, { useContext, useEffect } from 'react';
import { RoutesEnum } from '../../types/routes.enum';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { UserContext } from '../../context/UserContext';

export default function RedirectPage() {
    const { isAuthenticated, isLoading } = useAuth0();
    const router = useRouter();
    const haveBeenLoggedOut = router.query['logout'] === 'true';
    const { userContext } = useContext(UserContext);

    useEffect(() => {
        if (!isLoading && isAuthenticated && userContext.id) {
            router.push(RoutesEnum.USER_MANAGEMENT);
        } else if (!isLoading && !isAuthenticated) {
            router.push(
                RoutesEnum.LOGIN + (haveBeenLoggedOut ? '?logout=true' : ''),
            );
        }
    }, [haveBeenLoggedOut, isAuthenticated, isLoading, router, userContext.id]);
    return <></>;
}
