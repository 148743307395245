import React from 'react';
import Head from 'next/head';
import RedirectPage from '../src/component/redirect';

export default function Page() {
    return (
        <>
            <Head>
                <title>Admin portal</title>
            </Head>
            <main>
                <RedirectPage />
            </main>
        </>
    );
}
